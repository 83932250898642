<template>
	<div class="page_talent">
		<top :bgWhite='true' :showSearch="true" @searchFun="searchFun"></top>
		<div class="contentAll  padding-lr-30  top-80   ">
			<div class="" v-if="lists.length==0">
				<el-empty description="~空空如也~"></el-empty>
			</div>
				<el-row :gutter="24" v-else v-for="(Fitem,Findex) in lists" :key="Findex">
					<el-col :span="8"  v-for="(item,index) in Fitem" :key="index">
						<div class="list-item bg-white padding-20 radius-8 margin-bottom-20 point" @click="toDetail(item)">
							<div class="flex justify-between align-center solid-bottom padding-bottom-20" style="width: 100%;">
								<div class="flex align-center writer-info">
									<div class="head-box">
										<el-image :src="item.head_img" class="head-img margin-right-xs radius-48 block-48" fit="cover"></el-image>
										<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="item.is_v==1">
									</div>
									<!-- <img :src="item.head_img" class="block-48 radius-48 margin-right-xs"> -->
									<div class="left-info">
										<p class="name">{{item.user_nickname}}</p>
										<div class="writer-tag flex align-center margin-top-5" v-if="item.tags_info && item.tags_info.length>0 && item.tags_info[0]!=''">
											<p type="warning" size="mini" v-if="j<2" class="margin-right-5" v-for="(ele,j) in item.tags_info" :key="j">{{ele}}</p>
										</div>
									</div>
								</div>
								<el-button type="danger" v-if="item.user_id!=userInfo.id" round="" size="mini" class="text-white" @click.stop="addFocus(item)">{{item.is_focus==1 ? '已关注' :'+ 关注'}}</el-button>
							</div>
							<div class="margin-top-10">
								<p class="text-666 fs12 text-cut" style="word-break: break-all;">{{item.autograph || '这个人很神秘，什么都没留下'}}</p>
							</div>
						</div>
					</el-col>
				</el-row>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data(){
			return{
				page:1,
				lists:[],
				keywords:'',
				totalSize:0
			}
		},
		mounted() {
			this.loadData()
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo;
			}
		},
		methods:{
			// 加关注
			addFocus(item){
				this.ajax('post', '/v1/61419cb4727bd', {
					fuid: item.id
				}, res => {
					if (res.code == 1) {
						item.is_focus = item.is_focus == 1 ? 0 : 1
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 输入框搜索事件
			searchFun(e){
				console.log(e);
				this.keywords = e;
				this.loadData()
			},
			toDetail(item){
				this.$router.push({
					path:'/talents_detail',
					query:{
						user_id:item.id
					}
				})
			},
			// 人才列表
			loadData() {
				this.ajax('post', '/v1/613b188d1f6bd', {
					page:this.page,
					keyword:this.keywords
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						let currentData = res.data.data;
						var result = [];
						for(var i=0;i<currentData.length;i+=3){
						    result.push(currentData.slice(i,i+3));
						};
						this.lists =  result;
						console.log(this.lists);
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			},
		}
	}
</script>

<style  lang="scss" scoped>
html, body{
	.content{
		width: 100%;
		background: #FFFFFF;
	}
}
.contentAll{
	width: 62%;
}
.list-item{
	box-shadow: 1px 3px 8px 2px #eeeeee;
}
.active {
	position: relative;
	color: #333;
	font-weight: bold;
	font-size: 16px;
}

.active::after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	right: 0;
	width: 30%;
	margin: auto;
	background-color: #E54F42;
	height: 2px;
}
.item {
	width: 300px;
	height: 32px;
	line-height: 48px;
	background-color: #F6F7F9 !important;
	border: 0;
	border-radius: 24px;
	.input {
		width: 85%;
		font-size: 9px;
	}
	.send-code{
		font-size: 9px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 13px;
		color: #3E62B1;
		opacity: 1;
	}
}
.head-box{
	position: relative;
	.bigv{
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
}
.writer-tag {
	p {
		width: 66px;
		height: 20px;
		text-align: center;
		opacity: 1;
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #FFFFFF;
		opacity: 1;
		&:nth-child(1){
			background: url(../../assets/image/icon/label-bg1.png) no-repeat;
			background-size: cover;
		}
		&:nth-child(2){
			background: url(../../assets/image/icon/label-bg2.png) no-repeat;
			background-size: cover;
		}
	}
}
</style>